import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#80d6ff",
      main: "#284389",
      dark: "#0077c2",
    },
    secondary: {
      light: "#ffbd45",
      main: "#fb8c00",
      dark: "#c25e00",
    },
  },
});

export default theme;
