/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from "../images/favicon.ico"
import { Languages } from "@arnaud-cortisse/donotskip-core"
import { useTranslation, usePageContext } from "@3nvi/gatsby-theme-intl"
import { I18N_NAMESPACES } from "../i18next/i18n"

interface SEOProps {
  meta?: string[]
  keywords?: string[]
  title?: string
  description?: string
}
const SEO: React.FC<SEOProps> = ({
  meta = [],
  keywords = [],
  title = "DoNotSkip",
}) => {
  const { originalPath, lang } = usePageContext()
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON)

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        allFile(filter: { name: { eq: "share" } }) {
          nodes {
            publicURL
            childImageSharp {
              original {
                height
                width
              }
            }
          }
        }
      }
    `
  )

  const siteMetadata = data.site.siteMetadata
  const imageMetadata = data.allFile.nodes[0]
  const imagePublicUrl = imageMetadata.publicURL
  const imageDimensions = imageMetadata.childImageSharp.original

  const image = `${process.env.GATSBY_HOSTING_URL}${imagePublicUrl}`

  const metaDescription = tCommon("meta-description")

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={siteMetadata.title}
      titleTemplate={`${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(
          meta.length > 0
            ? {
                name: "meta",
                content: meta.join(" "),
              }
            : []
        )
        .concat([
          {
            property: "og:image",
            content: image,
          },
          {
            property: "og:image:width",
            content: imageDimensions.width,
          },
          {
            property: "og:image:height",
            content: imageDimensions.height,
          },
        ])}
    >
      <link rel="icon" href={favicon} />
      <link rel="canonical" href={`/${lang}${originalPath}`} />
      <title>{siteMetadata.title}</title>
    </Helmet>
  )
}

export default SEO
