/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import "../styles/default.css"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../styles/theme"
import { CssBaseline } from "@material-ui/core"
import SEO from "./seo"

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SEO />

      <main
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {children}
      </main>
    </ThemeProvider>
  )
}

export default Layout
